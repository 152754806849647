import React from 'react';
import '../styles/components/EducationCard.css';

/**
 * An Experience Card Component
 * @param {*} props expects bgFillColor, fillColor, icon, iconAltText, date, title, location, and description props
 * @returns An Experience Card populated with the passed props
 */
export default function EducationCard(props) {
	return (
		<>
			<div
				className="education-card"
				style={
					props.bgFillColors
						? {
								background: `linear-gradient(
												to bottom right,
												${props.bgFillColors[0]},
												${props.bgFillColors[1]}
											)`,
								backdropFilter: 'blur(2em)',
						  }
						: { backgroundColor: '#fff' }
				}
			>
				<div
					style={
						props.fillColor
							? { backgroundColor: props.fillColor }
							: { backgroundColor: '#ffc761' }
					}
					className="education-card__icon-container"
				>
					<img
						src={props.icon}
						alt={
							props.iconAltText ? props.iconAltText : 'an education card icon'
						}
					/>
				</div>
				<p
					style={
						props.fillColor ? { color: props.fillColor } : { color: '#ffc761' }
					}
					className="education-card__date"
				>
					{props.date}
				</p>
				<h3 className="education-card__title">{props.title}</h3>
				<svg
					width="8"
					height="10"
					viewBox="0 0 8 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M0.359863 3.81731C0.359863 1.81224 2.0248 0.181641 4.07209 0.181641C6.11939 0.181641 7.78432 1.81224 7.78432 3.81731C7.78432 6.33744 4.43342 9.64832 4.29112 9.7883C4.23048 9.84707 4.15129 9.87677 4.07209 9.87677C3.9929 9.87677 3.9137 9.84707 3.85307 9.7883C3.71077 9.64832 0.359863 6.33744 0.359863 3.81731ZM5.92805 3.81735C5.92805 4.82131 5.09704 5.63518 4.07194 5.63518C3.04683 5.63518 2.21582 4.82131 2.21582 3.81735C2.21582 2.81338 3.04683 1.99951 4.07194 1.99951C5.09704 1.99951 5.92805 2.81338 5.92805 3.81735Z"
						fill={props.fillColor ? props.fillColor : '#ffc761'}
					/>
				</svg>
				<p className="education-card__location">{props.location}</p>
				<p className="education-card__description">{props.description}</p>
			</div>
		</>
	);
}
