import React from 'react';
import EducationCard from '../components/EducationCard';
import scholarIcon from '../images/Design/3 Education/Assets/SVG/scholarIcon.svg';
import '../styles/pages/Education.css';

export default function Education(props) {
	let JFKMHS_COLORS = {
		main: 'rgb(0,102,0)',
		bg: ['rgba(255,255,255,0.2)', 'rgba(0,102,10,0.2)'],
	};
	let RUTGERS_COLORS = {
		main: 'rgb(179,14,55)',
		bg: ['rgba(255,255,255,0.2)', 'rgba(179,14,55,0.2)'],
	};
	return (
		<>
			<div className="page page__education">
				<h1 className="page-title">Education</h1>
				<div className="education-cards">
					<EducationCard
						bgFillColors={RUTGERS_COLORS.bg}
						fillColor={RUTGERS_COLORS.main}
						icon={scholarIcon}
						iconAltText="An icon of a scholar"
						date="2020 - 2024"
						title="BS in Computer Science and Computer Engineering"
						location="Rutgers University Honors Academy"
						description="I was accepted into the prestigeous Rutgers School of Engineering Honors Academy, and was exposed to numerous fields of study. I also connected with many brilliant peers and mentors through Rutgers."
					/>

					<EducationCard
						bgFillColors={JFKMHS_COLORS.bg}
						fillColor={JFKMHS_COLORS.main}
						icon={scholarIcon}
						iconAltText="An icon of a scholar"
						date="2016 - 2020"
						title="High School Diploma"
						location="JFK Memorial High School"
						description="I completed my secondary education at JFKMHS, located in Iselin NJ. I was introduced to STEM at this school -- both through the advanced curriculum as well as through my activities with the JFK FIRST Robotics Team."
					/>
				</div>
			</div>
		</>
	);
}
