import '../styles/components/ExperiencePoint.css';
import React from 'react';

/**
 *
 * @param {*} props Expects date, title, position, description, justifySide ('left' or 'right')
 * @returns Experience Point component with filled fields
 */
export default function ExperiencePoint(props) {
	let date = props.date ? props.date : '20XX - 20XX';
	let title = props.title ? props.title : 'Interesting Company';
	let position = props.position ? props.position : 'Lead Position';
	let description = props.description
		? props.description
		: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad incidunt molestias eos recusandae. Obcaecati dolorum dolor quod similique quae eveniet fugit eligendi excepturi, vitae fugiat reiciendis minus commodi tempore nulla?';
	let fillColor = props.fillColor ? props.fillColor : 'blue';
	let justifySide = props.justifySide ? props.justifySide : 'right';

	let textStyle = { textAlign: justifySide };
	let blockStyle =
		justifySide === 'right'
			? {
					justifySelf: 'end',
			  }
			: {
					justifySelf: 'start',
			  };
	let pointStyle =
		justifySide === 'right'
			? {
					width: '25px',
					height: '25px',
					borderRadius: '50%',
					background: fillColor,
					position: 'absolute',
					right: '-70px',
					top: '50%',
			  }
			: {
					width: '25px',
					height: '25px',
					borderRadius: '50%',
					background: fillColor,
					position: 'absolute',
					left: '-70px',
					top: '50%',
			  };

	return (
		<>
			<div className="experience-point" style={blockStyle}>
				<p className="experience-point__date" style={textStyle}>
					{date}
				</p>
				<h3
					className="experience-point__title"
					style={{ ...textStyle, position: 'relative', color: fillColor }}
				>
					{title}
					<div className="experience-point__point" style={pointStyle}></div>
				</h3>
				<p className="experience-point__position" style={textStyle}>
					{position}
				</p>
				<p className="experience-point__description" style={textStyle}>
					{description}
				</p>
			</div>
		</>
	);
}
