import React, { useState } from 'react';
import '../styles/components/Navbar.css';
import logo from '../images/Decor/MinimalistLogo.svg';
import { NavLink } from 'react-router-dom';

export default function MainNavbar(props) {
	let [burgerOpened, setBurgerOpened] = useState(false);

	const toggleBurgerOpenedState = () => {
		setBurgerOpened((prevBurger) => {
			return !prevBurger;
		});
	};

	return (
		<div className="main-nav">
			<NavLink exact to="/">
				<img src={logo} className="site-logo" alt="Hedgineering Logo" />
			</NavLink>
			<nav className={burgerOpened ? 'navbar nav-opened' : 'navbar nav-closed'}>
				<ul className="navbar-nav">
					<li>
						<NavLink exact to="/" onClick={() => toggleBurgerOpenedState()}>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink to="/education" onClick={() => toggleBurgerOpenedState()}>
							Education
						</NavLink>
					</li>
					<li>
						<NavLink to="/experience" onClick={() => toggleBurgerOpenedState()}>
							Experience
						</NavLink>
					</li>
					<li>
						<NavLink to="/projects" onClick={() => toggleBurgerOpenedState()}>
							Projects
						</NavLink>
					</li>
					<li>
						<NavLink to="/contact" onClick={() => toggleBurgerOpenedState()}>
							Contact
						</NavLink>
					</li>
				</ul>
			</nav>
			<span
				className={
					burgerOpened
						? 'hamburger-container burger-opened'
						: 'hamburger-container burger-closed'
				}
			>
				<button
					id="hamburger"
					className={burgerOpened ? 'x' : '_'}
					onClick={() => toggleBurgerOpenedState()}
				>
					<svg
						id="hamburger-svg"
						data-name="hamburger-svg"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 35 30"
					>
						<path
							id="top"
							className="line"
							d="M166.18,102.56h25s7.52-.43,5-5.29c-2.89-5.65-8.7,2.07-8.7,2.07L169.84,117"
							transform="translate(-166.18 -94.7)"
						/>
						<path
							id="bottom"
							className="line"
							d="M166.18,113.77h25s7.52.43,5,5.29c-2.89,5.64-8.7-2.07-8.7-2.07L169.84,99.31"
							transform="translate(-166.18 -94.7)"
						/>
						<line
							id="middle"
							className="line"
							y1="13.48"
							x2="25"
							y2="13.48"
							x1="0"
						/>
					</svg>
				</button>
			</span>
		</div>
	);
}
