import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
// Pages
import Home from './pages/Home';
import Education from './pages/Education';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
// Components
import MainNavbar from './components/MainNavbar';

function App() {
	/* I'll have to use state if I want to render different navbars for different pages,
	 * since rendering individual navbar components in each page causes refresh, breaking the closing animation.
	 */

	return (
		// Router basically makes this a component that will selectively render whichever Route has a path that best matches the current url
		<Router>
			<MainNavbar />

			{/* A <Switch> looks through its children <Route>s and
      			renders the first one that matches the current URL. */}
			<Switch>
				<Route exact path="/" component={Home} />
				<Route path="/education" component={Education} />
				<Route path="/experience" component={Experience} />
				<Route path="/projects" component={Projects} />
				<Route path="/contact" component={Contact} />
				<Route path="*" component={Home} />
				<Redirect to="/" />
			</Switch>
		</Router>
	);
}

export default App;
