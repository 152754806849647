import React from 'react';
import mailboxImg from '../images/Design/5 Subscribe/Assets/SVG/mailbox_img.svg';
import '../styles/pages/Contact.css';

export default function Contact(props) {
	return (
		<>
			<div className="page page__contact">
				<h1 className="page-title">Contact</h1>
				<div className="contact-form">
					<img
						src={mailboxImg}
						alt="A mailbox vector graphic"
						className="contact-form__mailbox-img"
					/>
					<form name="contact" method="post" onSubmit="submit">
						<input type="hidden" name="form-name" value="contact" />
						<h3 className="contact-form__title">Need Quality Work?</h3>
						<p className="contact-form__subtitle">
							Looking forward to hearing from you
						</p>
						<div className="contact-form__name">
							<label htmlFor="name">Name</label>
							<input
								type="text"
								name="name"
								id="name"
								placeholder="Billy Joel"
							/>
						</div>
						<div className="contact-form__email">
							<label htmlFor="email">Email</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="billy.joel@example.com"
							/>
						</div>
						<div className="contact-form__comments">
							<label htmlFor="comments">Got Any Comments?</label>
							<textarea
								name="comments"
								id="comments"
								placeholder="You're awesome!"
							/>
						</div>
						<button type="submit" className="btn contact-form__btn-submit">
							Submit
						</button>
					</form>
				</div>
			</div>
		</>
	);
}
