import React from 'react';
import ExperiencePoint from '../components/ExperiencePoint';
import img1 from '../images/Design/4 Experience/Assets/SVG/experience1_img.svg';
import img2 from '../images/Design/4 Experience/Assets/SVG/experience2_img.svg';
import '../styles/pages/Experience.css';

export default function Experience(props) {
	return (
		<>
			<div className="page page__experience">
				<h1 className="page-title">Experience</h1>
				<div className="experience-points">
					<img src={img1} alt="Cheerful Hedgineers Building." />
					<ExperiencePoint
						fillColor="#48C256"
						date="Aug. 2020 - Present"
						title="Lettuce Studios LLC"
						position="Project Architect, Version Control Manager"
						description="This is a game development startup that is currently working on a New-Age Multiplayer FPS Game called Project C133. I handle the standardization of scripting and work procedures to ensure code maintainability and prevent merge conflicts in our version control. I also develop custom game mechanics for Project C133 using the Mirror HLAPI Multiplayer Networking Solution and the C# language."
					/>
					<img src={img2} alt="Bright Hedgineers Creating." />
					<ExperiencePoint
						fillColor="rgb(179,14,55)"
						justifySide="left"
						date="Jul. 2021 - Present"
						title="Rutgers University"
						position="Office of IT Consultant in Training"
						description="I offer my technical knowledge to help fellow college students, faculty, and guests with the devices they're working with. I also contribute to the maintainanace of numerous machines across campus, including 50 printers and 5 computer labs."
					/>
					<img src={img2} alt="Bright Hedgineers Creating." />
					<ExperiencePoint
						fillColor="#222"
						date="Feb. 2021 - May 2021"
						title="Code Ninjas"
						position="Lead Sensei"
						description="I worked as a programming teacher here, leading instruction of children ages 5 to 15 in coding with Python, JavaScript, and Scratch. Every child would have their own game to create/work through, and I successfully guided several students in achieving their monthly goals."
					/>
					<img src={img1} alt="Cheerful Hedgineers Building." />
					<ExperiencePoint
						fillColor="rgb(0,102,0)"
						justifySide="left"
						date="Sep. 2018 - Jun. 2020"
						title="FIRST Robotics Team 3515"
						position="Leader of Robot Programming"
						description="This was the JFK Memorial High School Team for the FIRST Robotics Competition, an internationally recognized competition founded by Dean Kamen to promote STEM, Robotics and Collaboration for youth all over the world. I led a team of 8 other students in researching, designing, and developing code and sensor systems for a competitive robot."
					/>
				</div>
			</div>
		</>
	);
}
