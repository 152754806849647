import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/home.css';
import pfpImage from '../images/Decor/pfpDarkBg.png';
import tttImage from '../images/ProjThumbnails/TicTacToe.png';
import blImage from '../images/ProjThumbnails/Booklogger.png';
import ajaxImage from '../images/ProjThumbnails/Ajax.png';

export default function Home(props) {
	return (
		<>
			<div className="page page__home">
				<main>
					<section className="intro-section">
						<div className="intro-card">
							<div className="intro-card__content">
								<div className="intro-card__front">
									<h2 className="signature intro-card__signature">
										Rahul Hegde
									</h2>
									<p className="intro-card__subtitle">
										Aspiring Game and Web Developer
									</p>
								</div>
								{/* Close Front */}
								<div className="intro-card__back">
									<p className="intro-card__body">Greetings esteemed viewer!</p>
									<p className="intro-card__body">
										My mission is to bring value to people just like you with
										fantastic entertainment and valuable online tools.
									</p>
									<p className="intro-card__body">
										I absolutely love learning and creating. Leveling up my
										skills enhances my ability to bring value to people just
										like you!
									</p>
								</div>
								{/* Close Back */}
							</div>
							{/* Close Intro Card Content */}
						</div>
						{/* Close Intro Card */}
					</section>
					{/* Close Intro Section */}
					<section className="project-preview-section">
						<h2 className="section-heading">Check Out My Projects!</h2>
						<div className="project-cards">
							<div className="project-card">
								<a
									href="https://hedgineering-tic-tac-toe.netlify.app"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={tttImage}
										alt="thumbnail of a project"
										className="project-card-img"
									/>
									<h3 className="project-card-title">Tic Tac Toe</h3>
									<p className="project-card-description">
										A fun rendition of the classic game! This was a project I
										used to learn advanced CSS selectors.
									</p>
								</a>
							</div>
							<div className="project-card">
								<a
									href="http://hedgineering-book-logger.netlify.app"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={blImage}
										alt="thumbnail of a project"
										className="project-card-img"
									/>
									<h3 className="project-card-title">Book Logger</h3>
									<p className="project-card-description">
										A clean little tracker for what books you want to read. I
										made this to learn JS DOM manipulation.
									</p>
								</a>
							</div>
							<div className="project-card">
								<a
									href="http://https://hedgineering-ajax-github-api.netlify.app"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={ajaxImage}
										alt="thumbnail of a project"
										className="project-card-img"
									/>
									<h3 className="project-card-title">AJAX with GitHub</h3>
									<p className="project-card-description">
										A simple, GitHub api call visual. I created this to learn
										how to use Asynchronous JS.
									</p>
								</a>
							</div>
						</div>
						<Link to="/projects" exact>
							<button className="btn btn-medium btn-accent">See More</button>
						</Link>
					</section>
					{/* Close Project Preview Section */}
					<section className="more-section">
						<h2 className="section-heading">More About Me </h2>
						<div className="circle-img-container">
							<img
								src={pfpImage}
								alt="A happy Hedgineer, smiling plesantly at you!"
								className="img-pfp"
							/>
						</div>
						<p>You want to get to know me better? Explore my website!</p>
						<div className="btn-container">
							<Link to="/experience" exact>
								<button className="btn smaller">Experience</button>
							</Link>
							<Link to="/contact" exact>
								<button className="btn btn-accent">Contact</button>
							</Link>
							<Link to="/education" exact>
								<button className="btn smaller">Education</button>
							</Link>
						</div>
					</section>
				</main>
			</div>
		</>
	);
}
