import React from "react";
import jedi from "../images/ProjThumbnails/JediApp.png";
import bmp from "../images/ProjThumbnails/BuyMeProj.png";
import ttt from "../images/ProjThumbnails/TicTacToe.png";
import bl from "../images/ProjThumbnails/Booklogger.png";
import blLarge from "../images/ProjThumbnails/BookloggerLarge.png";
import ajax from "../images/ProjThumbnails/Ajax.png";
import mosiac from "../images/ProjThumbnails/mosiacGrid.png";
import typing from "../images/ProjThumbnails/typingDog.png";
import accordion from "../images/ProjThumbnails/fem-faq-accordion.png";
import stats from "../images/ProjThumbnails/fem-stats-card.png";
import d7 from "../images/ProjThumbnails/cssd7.png";
import d6 from "../images/ProjThumbnails/cssd6.png";
import d5 from "../images/ProjThumbnails/cssd5.png";
import d4 from "../images/ProjThumbnails/cssd4.png";
import d3 from "../images/ProjThumbnails/cssd3.png";
import d2 from "../images/ProjThumbnails/cssd2.png";
import d1 from "../images/ProjThumbnails/cssd1.png";
import "../styles/pages/Projects.css";

export default function Projects(props) {
  return (
    <>
      <div className="page page__projects">
        <h1 className="page-title">Projects</h1>
        <div className="container">
          <h2>View Them Live!</h2>
          <div className="projects-container">
            <div className="project">
              <a
                href="https://youtu.be/-KYa9PMuaZo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={jedi}
                  alt="A stock market investing application to help users invest with confidence."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://youtu.be/IfS-iYa2GDc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={bmp}
                  alt="An E-Commerce Auction Website made to explore Client-Server relationships, MySQL CRUD Operations, and automated trading."
                  max-width="500px"
                  width="clamp(95vw, 100%, 500px)"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-tic-tac-toe.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ttt}
                  alt="A fun rendition of the classic game! This was a project I
										used to learn advanced CSS selectors."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-book-logger.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                {window.matchMedia("(min-width: 1700px)").matches ? (
                  <img
                    src={blLarge}
                    alt="
										A clean little tracker for what books you want to read. I
										made this to learn JS DOM manipulation.
							"
                    max-width="500px"
                  />
                ) : (
                  <img
                    src={bl}
                    alt="
										A clean little tracker for what books you want to read. I
										made this to learn JS DOM manipulation.
							"
                    max-width="500px"
                  />
                )}
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-ajax-github-api.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ajax}
                  alt="
										A simple, GitHub api call visual. I created this to learn
										how to use Asynchronous JS.
							"
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-grid-mosiac.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mosiac}
                  alt="A clean looking mosiac layout created with CSS grid."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-typing-animation.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={typing}
                  alt="A cute little typing animation created with CSS pseudo elements."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-femc-faq-accordion-card.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={accordion}
                  alt="A Front End Mentor Challenge for creating an accordion widget for Frequently Asked Questions."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-femc-stats-preview-card.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={stats}
                  alt="A Front End Mentor Challenge for creating a stats preview card for a business."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day7.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d7}
                  alt="A simple but aesthetic nav-bar with animated highlighting that I created on the 7th day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day6.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d6}
                  alt="A responsive article template I created with HTML/CSS on the 6th day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day5.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d5}
                  alt="A simple showcase page with an image parallax effect I created with HTML/CSS on the 5th day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day4.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d4}
                  alt="A clean looking e-commerce product card template I created with HTML/CSS on the 4th day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day3.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d3}
                  alt="A clone of the car showcase from the Tesla website I created with HTML/CSS on the 3rd day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day2.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d2}
                  alt="An aesthetic blog post preview card I created with HTML/CSS on the 2nd day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
            <div className="project">
              <a
                href="https://hedgineering-css-summer-challenge-day1.netlify.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={d1}
                  alt="A dark scheme basic page layout template I created with HTML/CSS on the 1st day of my Summer CSS Challenge."
                  max-width="500px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
